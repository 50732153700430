<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-tariff-performer
        :tariffPerformerBase="tariffPerformerBase"
        @timerAfto="timerAfto"
        @createTariffPerformer="createTariffPerformer"
        @editForm="editForm"
        @copyTariffPerformer="copyTariffPerformer"
        @refresh="refresh"
      />
    </div>
    <modal-tariff-performer
      :tariffPerformerBase="tariffPerformerBase"
      :division="division"
      :type_tariff_performer="type_tariff_performer"
      :car_classes="car_classes"
      :dop_options="dop_options"
      @refresh="refresh"
    />
    <modal-edit-performer-tariff
      :id="id"
      :division="division"
      :type_tariff_performer="type_tariff_performer"
      :car_classes="car_classes"
      :dop_options="dop_options"
      @refresh="refresh"
    />
    <modal-copy-performer-tariff
      :id="id"
      :division="division"
      :type_tariff_performer="type_tariff_performer"
      :car_classes="car_classes"
      :dop_options="dop_options"
      @refresh="refresh"
    />
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
      :name="name"
    />
  </div>
</template>

<script>
import tableTariffPerformer from "@/views/component/Table/tableTariffPerformer.vue";
import ModalTariffPerformer from "@/views/component/Modal/ModalTariffPerformer/ModalTariffPerformer.vue";
import ModalEditPerformerTariff from "@/views/component/Modal/ModalTariffPerformer/ModalEditPerformerTariff.vue";
import ModalCopyPerformerTariff from "@/views/component/Modal/ModalTariffPerformer/ModalCopyPerformerTariff.vue";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    tableTariffPerformer,
    ModalTariffPerformer,
    ModalEditPerformerTariff,
    ModalCopyPerformerTariff,
  },
  data() {
    return {
      showPreloader: false,
      tariffPerformerBase: [],
      type_tariff_performer: [],
      dop_options: [],
      car_classes: [],
      division: [],
      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Имя" },
        { key: "division_base_tariff", label: "Подразделения" },
        { key: "sort_number", label: "Порядок тарифа" },
        { key: "type_tariff_name", label: "Тип тариф" },
      ],
      id: null,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.getTariffPerformerData();
    this.getDivision();
    this.openFilter();
  },
  methods: {
    refresh() {
      this.showPreloader = true;
      this.$http.get(`${this.name}`).then((res) => {
        this.tariffPerformerBase = res.data;
        this.$store.commit(
          "pageData/setdataCount",
          this.tariffPerformerBase.length
        );
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    sendToParent(tableData) {
      this.tariffPerformerBase = tableData;
      this.$store.commit(
        "pageData/setdataCount",
        this.tariffPerformerBase.length
      );
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.name}`, { params: param }).then((res) => {
          this.tariffPerformerBase = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.tariffPerformerBase.length
          );
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    getTariffPerformerData() {
      this.$http.get(`${this.name}/data`).then((res) => {
        this.type_tariff_performer = res.data.type_tariff_performer;
        this.dop_options = res.data.dop_options;
        this.car_classes = res.data.car_classes;
      });
    },
    getDivision() {
      this.$http.get("divisions?active=1").then((res) => {
        this.division = res.data;
      });
    },
    timerAfto() {
      this.$store.state.updates.update
        ? (this.$store.state.updates.timer = setInterval(
            () => this.refresh(),
            30000
          ))
        : clearInterval(this.$store.state.updates.timer);
    },
    createTariffPerformer() {
      this.$bvModal.show("createTariffPerformerBase");
    },
    editForm(id) {
      this.id = id;
      setTimeout(this.openModalEdit, 0);
    },
    openModalEdit() {
      this.$bvModal.show("EditPerformerTariff");
    },
    copyTariffPerformer(id) {
      this.id = id;
      setTimeout(this.openModalCopy, 0);
    },
    openModalCopy() {
      this.$bvModal.show("CopyPerformerTariff");
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        this.$http.get(`${this.name}`, { params: param }).then((res) => {
          this.tariffPerformerBase = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.tariffPerformerBase.length
          );
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
  beforeDestroy() {
    if (this.$store.state.updates.timer) {
      clearInterval(this.$store.state.updates.timer);
    }
  },
};
</script>
